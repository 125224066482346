import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const CheckboxIcon = styled.div`
  ${tw`w-5 h-5 transition rounded-base border bg-ghostWhite border-lavenderGrey mr-2 flex justify-center items-center`}

  svg {
    ${tw`opacity-0 transition-all w-3`}
  }
`

export const CheckboxLabel = styled.span`
  ${tw`mr-2 text-sm text-blueBayoux block font-semibold`}
`

export const Checkbox = styled.label<{ error?: boolean }>`
  ${tw`flex items-center relative cursor-pointer select-none`}

  &:hover {
    ${CheckboxIcon} {
      ${tw`border-primary`}
    }
  }

  input {
    ${tw`opacity-0 absolute`}

    &:checked ~ ${CheckboxIcon} {
      ${tw`bg-primary border-primary`}

      svg {
        ${tw`opacity-100 text-white`}
      }
    }
  }

  ${({ error }) =>
    error &&
    css`
      ${CheckboxIcon} {
        ${tw`border-danger`}
      }
    `}
`
