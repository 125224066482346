import { FormGroup } from '@Styles/form.styled'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { removeAuthorizationHeader } from '@Utils/token'
import { useRouter } from 'next/router'
import Icon from '../Global/Icon'
import Title from '../Global/Title'
import Button from '../Global/Button'
import Link from 'next/link'
import { LOGIN_FORM } from '@Constants/login'
import { AuthApi } from '@Services/api/auth'
import { setCookie } from '@Services/cookies'
import Typography from '../Global/Typography'
import { useForm } from 'react-hook-form'
import useYupValidationResolver from '../../hooks/useYupValidationResolver'
import { getInputProps } from '@Utils/form'
import Input from '../Global/FormInput/Input'
import ErrorMsg from '../Global/FormInput/ErrorMsg'
import Checkbox from '../Global/FormInput/Checkbox'
import Alert from '../Global/Alert'
import { UserContext } from '@Contexts/UserContext'
import { AuthSocialSetting } from '@Services/types'
import * as S from './Login.styled'
import { useGoogleLogin } from '@react-oauth/google'

interface LoginFormProps {
  clientIds: AuthSocialSetting[]
}

const LoginForm: React.FC<LoginFormProps> = ({ clientIds }) => {
  const [error, setError] = useState<string>('')
  const [inputType, setInputType] = useState<'password' | 'text'>('password')
  const router = useRouter()
  const { user } = useContext(UserContext)
  const validationSchema = useMemo(() => LOGIN_FORM.schema, [])
  const resolver = useYupValidationResolver(validationSchema)
  const toggleInputType = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password')
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, submitCount },
  } = useForm({
    resolver,
    defaultValues: { ...LOGIN_FORM.initialValues },
  })
  const onLoginSuccess = (key: string, rememberMe?: boolean) => {
    if (rememberMe) {
      let now = new Date()
      now.setMonth(now.getMonth() + 1)
      setCookie('token', key, now)
    } else {
      setCookie('token', key)
    }
    router.push('/dashboard')
  }

  const onSocialLoginError = () => {
    setError(LOGIN_FORM.error.social)
  }

  const onSubmit = async ({ username, password, remember_me }: any) => {
    try {
      setError('')
      const { key } = await AuthApi.login(username, password)
      onLoginSuccess(key, remember_me)
    } catch (e) {
      setError(LOGIN_FORM.error.common)
    }
  }

  const handleGoogleLogin = async (access_token: string) => {
    try {
      setError('')
      const { key } = await AuthApi.googleLogin({ access_token })
      onLoginSuccess(key)
    } catch (e) {
      console.error(e)
      onSocialLoginError()
    }
  }

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await handleGoogleLogin(codeResponse.access_token)
    },
  })

  useEffect(() => {
    removeAuthorizationHeader()
  }, [])

  const googleClient = clientIds.find(
    (client) => client.name.toLowerCase() === 'google'
  )

  return (
    <S.FormWrap>
      <S.LoginForm>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormGroup>
            <Title level={1}>Log In</Title>
          </FormGroup>
          {user?.isLogout ? (
            <FormGroup>
              <Alert
                type='success'
                title='Succesfuly signed out'
                content='You can login again below'
              />
            </FormGroup>
          ) : (
            <>
              <FormGroup>
                <Typography size='lg' color='blueBayoux'>
                  Welcome back, you’ve been missed!
                </Typography>
              </FormGroup>
              <S.SocialSignIn></S.SocialSignIn>
            </>
          )}
          <S.EmailSignIn>
            <FormGroup>
              <Input
                {...getInputProps('username', register, errors, submitCount)}
                prefixIcon={<Icon type='@' />}
                placeholder='Your Email'
              />
              <ErrorMsg message={errors.username?.message} />
            </FormGroup>
            <FormGroup>
              <Input
                {...getInputProps('password', register, errors, submitCount)}
                type={inputType}
                prefixIcon={<Icon type='lock' />}
                suffixIcon={
                  <Icon
                    type={inputType === 'password' ? 'eye-close' : 'eye'}
                    onClick={toggleInputType}
                  />
                }
                placeholder='Password'
              />
              <ErrorMsg message={errors.password?.message} />
            </FormGroup>

            <FormGroup>
              <S.FormFooter>
                <Checkbox
                  label={
                    <S.TermAndCondition>
                      I agree to the{' '}
                      <Link href='/terms-and-conditions' passHref>
                        terms and conditions
                      </Link>
                    </S.TermAndCondition>
                  }
                  name='terms_and_conditions'
                  error={errors.terms_and_conditions?.message ? true : false}
                  register={register('terms_and_conditions')}
                />
                <Checkbox
                  label='Remember Me'
                  name='remember_me'
                  register={register('remember_me')}
                />
              </S.FormFooter>
            </FormGroup>

            {error && (
              <FormGroup className='text-center'>
                <ErrorMsg message={error} />
              </FormGroup>
            )}
            <FormGroup center>
              <Button
                disabled={isSubmitting}
                type='submit'
                size='lg'
                block
                primary
              >
                <span>Log In</span>
              </Button>
            </FormGroup>
            {googleClient && (
              <FormGroup center key={googleClient.client_id}>
                <Button
                  size='lg'
                  iconPosition='left'
                  block
                  onClick={() => onGoogleLogin()}
                >
                  <Icon type='google' />
                  <span>Sign In with Google</span>
                </Button>
              </FormGroup>
            )}
          </S.EmailSignIn>

          <FormGroup center>
            <Link href='/forgot-password' passHref>
              <S.ForgotYourPassword>Forgot your password?</S.ForgotYourPassword>
            </Link>
          </FormGroup>
        </form>
      </S.LoginForm>
    </S.FormWrap>
  )
}

export default LoginForm
