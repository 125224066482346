import React, { HTMLAttributes } from 'react'
import { ReactSVG } from 'react-svg'
import { colors } from '@Styles/theme'
import { IconType } from './type'

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  type: IconType
  className?: string
  color?: string
  onClick?: (e: any) => void
}

export const Icon = React.forwardRef((props: IconProps, ref) => {
  const { type, onClick, className, color } = props

  return (
    <ReactSVG
      {...props}
      className={`${className ? className : ''} svg-icon ${
        onClick ? 'cursor-pointer	' : ''
      }`}
      onClick={onClick}
      src={`/static/icons/${type}.svg`}
      wrapper='span'
      style={{ color: colors[color] }}
      // @ts-ignore
      ref={ref}
    />
  )
})

export default Icon
