import styled from 'styled-components'
import tw from 'twin.macro'
import { DEVICES } from '../../constants/global'

export const FormWrap = styled.div`
  height: auto;
  ${tw`items-center flex justify-center pt-24 px-4`}
  ${tw`mobile:(h-auto text-sm)`}

  h1 {
    ${tw`mobile:(text-2xl)`}
  }

  p {
    ${tw`mobile:(text-base)`}
  }
`

export const LoginForm = styled.div`
  max-width: 540px;
  ${tw`my-0 mx-auto w-full flex relative z-10 text-center`}
  ${tw`mobile:(pb-8)`}

  form {
    ${tw`w-full`}

    > div {
      ${tw`mobile:(mb-4)`}
    }

    @media ${DEVICES.mobile} {
      label,
      input,
      span {
        ${tw`mobile:(text-sm)`}
      }
    }
  }
`

export const EmailSignIn = styled.div`
  text-align: left;
`

export const SocialSignIn = styled.div`
  ${tw`flex items-center justify-center -mx-2`}

  @media ${DEVICES.mobile} {
    margin-bottom: 0 !important;
  }

  > div {
    ${tw`w-full md:w-1/3 px-2`}
  }

  button {
    ${tw`pl-0 pr-0 w-full`}
    ${tw`mobile:(text-sm)`}
    
    .svg-icon {
      svg {
        ${tw`mobile:(mr-2)`}
      }
    }
  }
`

export const Or = styled.div`
  ${tw`relative right-auto bottom-auto block border-t my-10 border-l-0`}

  @media ${DEVICES.mobile} {
    margin: 1.25rem 0 1.5rem !important;
  }

  p {
    ${tw`absolute flex items-center justify-center left-1/2 w-10 h-10 -mt-5 -ml-5 bg-azureishWhite font-medium`}
    ${tw`mobile:(text-sm)`}
  }
`

export const FormFooter = styled.div`
  ${tw`flex justify-between`}
  ${tw`mobile:(block)`}

  label {
    ${tw`mobile:(mb-4)`}

    a {
      ${tw`mobile:(inline-block)`}
    }
  }
`

export const TermAndCondition = styled.span`
  a {
    ${tw`underline`}
  }
`

export const ForgotYourPassword = styled.span`
  ${tw`text-primary font-medium ml-4 leading-6 cursor-pointer`}
`
