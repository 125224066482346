import { NextPageContext } from 'next'
import LoginForm from '../../src/components/Login'
import SimpleLayout from '../../src/layouts/SimpleLayout'
import { AuthApi } from '@Services/api/auth'
import { AuthSocialSetting } from '@Services/types'
import React from 'react'

export interface LoginPageProps {
  clientIds: AuthSocialSetting[]
}

const LoginPage: React.FC<LoginPageProps> = ({ clientIds }) => {
  return (
    <SimpleLayout title='Login'>
      <LoginForm clientIds={clientIds} />
    </SimpleLayout>
  )
}

export async function getServerSideProps(ctx: NextPageContext) {
  const { results } = await AuthApi.getSocialSettings()
  return {
    props: {
      clientIds: results,
    },
  }
}

export default LoginPage
