import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Alert = styled.div<{
  type: 'success' | 'warning' | 'danger' | 'info' | 'default'
}>`
  ${tw`py-2 px-4 rounded-base inline-flex items-center leading-4`}

  svg {
    ${tw`text-success mr-2.5`}
  }

  ${({ type }) => {
    switch (type) {
      case 'success':
        return tw`bg-successLight text-success`
      case 'warning':
        return tw`bg-warningLight text-warning`
      case 'danger':
        return tw`bg-dangerLight text-danger`
      case 'info':
        return tw`bg-primaryLight text-primary`
      default:
        return tw`bg-primaryLight text-primary`
    }
  }}
`

export const Title = styled.h3`
  ${tw`font-medium text-sm`}
`

export const Content = styled.span`
  ${tw`text-grey text-xs`}
`
