import { ReactNode } from 'react'
import Icon from '../Icon'
import * as S from './Alert.styled'

interface AlertProps {
  type: 'success' | 'warning' | 'danger' | 'info' | 'default'
  title?: string
  content: ReactNode
}

const Alert = (props: AlertProps) => {
  const { title, content, type } = props

  return (
    <S.Alert type={type}>
      <Icon type={type === 'success' ? 'check-circle' : 'check-circle'} />
      <div>
        {title && <S.Title>{title}</S.Title>}
        <S.Content>{content}</S.Content>
      </div>
    </S.Alert>
  )
}

Alert.defaultProps = {
  type: 'default',
}

export default Alert
