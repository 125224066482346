import { InputHTMLAttributes, ReactNode } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import Icon from '../../Icon'
import * as S from './Checkbox.styled'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode
  error?: boolean
  register?: UseFormRegisterReturn
  labelFontWeight?: 400 | 500
  position?: 'left' | 'right'
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, error, defaultChecked, register, labelFontWeight, position = 'right' } = props

  const inputProps = {
    ...props,
    id: props.name,
    type: props.type || 'checkbox',
    className: props.name,
    defaultChecked: defaultChecked,
  }

  return (
    <S.Checkbox error={error} style={props.style}>
      {register ? (
        <input {...inputProps} {...register} />
      ) : (
        <input {...inputProps} />
      )}
      {position === 'right' && (
        <S.CheckboxLabel style={{ fontWeight: labelFontWeight }}>
          {label}
        </S.CheckboxLabel>
      )}
      <S.CheckboxIcon>
        <Icon type='check' />
      </S.CheckboxIcon>
      {position === 'left' && (
        <S.CheckboxLabel style={{ fontWeight: labelFontWeight }}>
          {label}
        </S.CheckboxLabel>
      )}
    </S.Checkbox>
  )
}

export default Checkbox
