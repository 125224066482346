import * as Yup from 'yup'
import { ERROR_MESSAGE } from './message'

export const LOGIN_FORM = {
  initialValues: {
    username: '',
    password: '',
    remember_me: false,
    terms_and_conditions: false,
  },
  schema: Yup.object().shape({
    username: Yup.string().required(ERROR_MESSAGE.required),
    password: Yup.string().required(ERROR_MESSAGE.required),
    terms_and_conditions: Yup.boolean()
      .required(ERROR_MESSAGE.required)
      .oneOf([true], ERROR_MESSAGE.required),
  }),
  error: {
    common: 'Sorry, unable to log in with the username and password provided.',
    social: 'Sorry, unable to log in with your social account.',
  },
}
